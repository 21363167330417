<template>
  <div :style="{backgroundColor: $route.query.backgroundColor ||'#eee'}" class="container prow-40">
    <h1 class="title pt-20 pb-10">《社区规范》</h1>
    <div class="f-fs-fe f-col date">
      <span>生效发布日期：2021年08月16日 </span>
      <span>更新日期：2021年08月16日 </span>
    </div>
    <div>
      <p class="fw-b">导言</p>
      <div class="ti-1">
        为了打造一个分享快乐的社区，幼享乐 团队制定本社区规范。幼享乐
        团队将依据本规范中的条款对注册用户和发布的内容进行管理。采取的措施包括但不限于更改或删除用户发布的内容、暂停或终止用户使用
        幼享乐
        的权利。所有有意降低内容质量、伤害社区氛围的行为（以下称「破坏」）都是不能容忍的。
        若对本规范的理解或执行有任何疑惑或争议，可以在「我的」-「更多」-「用户反馈」中填写建议给幼享乐
        团队或在幼享乐
        App内联系我们的客服人员进行反馈，我们将根据有关规则予以解释或处理。
      </div>
    </div>
    <div>
      <h2 class="ul-title pt-20 pb-10">一.违规行为界定</h2>
      <div>
        1.违反法律法规：
        发布违反法律法规，危害国家及社会安全的信息，包括但不限于：
        <div class="ti-1">（1）反对宪法所确定的基本原则；</div>
        <div class="ti-1">
          （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一；
        </div>
        <div class="ti-1">（3）损害国家荣誉和利益；</div>
        <div class="ti-1">（4）煽动民族仇恨、民族歧视，破坏民族团结；</div>
        <div class="ti-1">（5）破坏国家宗教政策，宣扬邪教和封建迷信；</div>
        <div class="ti-1">（6）散布谣言，扰乱社会秩序，破坏社会稳定；</div>
        <div class="ti-1">
          （7）宣扬淫秽、赌博、暴力、凶杀、恐怖或者教唆犯罪；
        </div>
        <div class="ti-1">
          （8）发布有关规定中明确禁止的淫秽内容（如性器官、性行为、性心理的详细描写），或发布引诱性图片文字引导用户进行线上线下的色情服务交易；
        </div>
        <div class="ti-1">
          （9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；
        </div>
        <div class="ti-1">（10）诽谤他人，泄露他人隐私，侵害他人合法权益。</div>
        2.危害民族功德和民族优良文化传统：
        发布淫秽色情、血腥暴力、违禁违规信息等相关内容，包括但不限于：
        <div class="ti-1">
          （1）散播淫秽、色情类信息，包括但不限于招嫖、裸聊或偷拍走光等内容。或在其他用户动态下评论有骚扰性质的文字信息；
        </div>
        <div class="ti-1">
          （2）引人不适具有极度视觉冲击的血腥内容或蓝鲸游戏、人体刺绣等记录自残、自杀的相关内容；
        </div>
        <div class="ti-1">
          （3）发布国家法律明令禁止的违禁违规物品售卖信息；
        </div>
        <div class="ti-1">（4）官方未证实的社会谣言类内容；</div>
        <div class="ti-1">
          （5）发布揭露社会负面，使得舆论导向有偏颇的内容。例如：校园暴力视频、虐童视频等.
        </div>
        3.危害国家安全和政治稳定： 发布涉及政治领导人负面信息，包括但不限于：
        <div class="ti-1">（1）冒用国内外领导人形象注册账号；</div>
        <div class="ti-1">
          （2）使用国内外领导人的姓名（含谐音字）作为账号注册名称的；
        </div>
        <div class="ti-1">（3）传播涉及党和国家领导人不实谣言信息；</div>
        <div class="ti-1">
          （4）传播有损领导人形象的歌谣、顺口溜、诗词、小品、段子；
        </div>
        <div class="ti-1">
          （5）违反《治安管理处罚法》，有明确目的、组织性的行动，如：集体请愿、上访、游行、集会、罢工、罢市、抗法。
        </div>
        4.侵害公民权利利益：
        在未经许可的情况下发布涉及侵占其他用户或企业隐私权、肖像权、名誉权、知识产权等信息，包括但不限于：
        <div class="ti-1">
          （1）通过互联网发表他人隐私、披露他人不愿意公开的个人信息（身份证信息、工作单位、手机号码、邮件地址、亲友联系方式、姓名、照片等）；
        </div>
        <div class="ti-1">
          （2）捏造事实公然发表过激言论、恶意诽谤中伤他人，丑化他人人格及形象，用粗俗、下流、威胁性、侮辱性言论，胁迫骚扰他人等方式损害他人名誉，为达到不正当竞争的目的，因交易发生矛盾而诽谤污蔑，侵犯商家名誉权的言论和行为；
        </div>
        <div class="ti-1">
          （3）以非法上传、下载、在线点播、搜索链接（电影、歌曲、文章、书籍等）等侵犯他人著作权的；未经许注册、使用域名、侵犯了他人商标权的言论和行为；
        </div>
        <div class="ti-1">
          （4）侵犯姓名权、名称权、肖像权，冒用或者盗用他人的名义和个人资料进行用户注册、仿冒他人发布文章、贴文等的言论和行为；
        </div>
        5.不友善行为： 不尊重 幼享乐 用户及其所贡献内容的行为，包括但不限于：
        <div class="ti-1">
          （1）骚扰：以评论、@
          他人等方式对他人反复发送重复或者相似的诉求，或者以下流、淫秽语言挑逗同性/异性；
        </div>
        <div class="ti-1">（2）谩骂：以不文明的语言对他人进行负面评价；</div>
        <div class="ti-1">
          （3）羞辱：贬低他人的能力、行为、生理或身份特征，让对方难堪；
        </div>
        <div class="ti-1">
          （4）歧视：针对他人的民族、种族、宗教、性取向、性别、年龄、地域、生理特征等身份或者归类的攻击；
        </div>
        <div class="ti-1">
          （5）挑衅：以不友好的方式激怒他人，意图使对方对自己的言论作出回应，蓄意制造事端。
        </div>
        6.恶意行为： 影响用户体验、扰乱 幼享乐
        社区秩序的信息或影响运营安全的行为，包括但不限于：
        <div class="ti-1">
          （1）冒充幼享乐 站内及相关业务官方账号或工作人员；
        </div>
        <div class="ti-1">
          （2）冒充他人，通过头像、用户名等个人信息暗示自己与他人相等同或有关联；
        </div>
        <div class="ti-1">
          （3）侵犯 幼享乐 知识产权，发布、使用、恶搞 幼享乐 logo
          等，或者容易与目前已有 幼享乐 产品设计主题、外观等混淆的内容；
        </div>
        <div class="ti-1">
          （4）发布含有钓鱼网站、木马、病毒网站链接及相关内容；
        </div>
        <div class="ti-1">（5）发布含有潜在危险、窃取用户隐私等相关内容；</div>
        <div class="ti-1">（6）胡乱举报没有违规行为的用户和状态；</div>
        <div class="ti-1">
          （7）以隐瞒事实或隐瞒真相的方式，骗取其他用户金钱财产的诈骗类信息.
        </div>
        7.发布垃圾信息： 以营利为目的，发布影响用户体验、扰乱 幼享乐
        社区秩序的信息或相关行为，包括但不限于：
        <div class="ti-1">
          （1）重复发布相似内容（包括但不限于发布动态、帖子）；
        </div>
        <div class="ti-1">
          （2）拥有多个帐号，或者与多个帐号合作谋求不正当曝光；
        </div>
        <div class="ti-1">
          （3）买卖帐号、或使用同一账号同一设备重复灌水的行为；
        </div>
        <div class="ti-1">
          （4）代购、卖鞋、祛斑加公众号等影响其他用户体验的广告类信息。
        </div>
      </div>
      <h2 class="ul-title pt-20 pb-10">二.处理流程</h2>
      <div>
        <div class="ti-1">
          通过主动发现和接受用户举报两种方式发现违规行为。用户可以通过联系幼享乐
          App内的客服人员或使用「我的」-「更多」页面内的「用户反馈」功能对涉嫌违规的行为和用户进行投诉。
          违规的信息由 幼享乐
          社区管理员根据本规定直接处理。一旦被认定发布以上内容或者有以上行为，账号将被执行以下操作：
        </div>

        <div class="ti-1">
          （1）警告：违规内容 1-3
          条，管理员将删除相应内容，用户将在「我的消息」页面收到系统违规提醒，用户至多会收到3次系统违规提醒；
        </div>
        <div class="ti-1">
          （2）永久封禁：已有警告记录3次，再次发布违规内容，管理员将删除相应内容，并保留直接永久停用幼享乐
          帐号的权利。
        </div>
        <div class="ti-1">
          暂时没有被删除的违规内容并不意味着 幼享乐
          容许它的存在，如果看到了符合违规标准但是没有被删除的内容，请尽快在幼享乐
          App内联系我们的客服人员进行举报，我们会在举报之后 24 小时以内去处理。
          本规范为动态文档，幼享乐
          有权根据相关法律法规或产品运营的需要对其内容进行修改并更新，请反复查看以便获得最新信息。
          幼享乐
          希望创造一个记录，分享，快乐的社区，希望大家能够友善对待他人，一起营造和维护这个快乐之地。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommunityNorms'
};
</script>

<style lang="scss" scoped>
.title,
.txt {
  font-size: 0.28rem;
  color: #0a1738;
  line-height: 0.4rem;
  letter-spacing: 0.02rem;

  .ul-title {
    font-size: 0.28rem;
    font-weight: bold;
  }
  .li-title {
    font-size: 0.26rem;
  }
}
.title {
  text-align: center;
  font-weight: bold;
}
.date {
  font-size: 0.2rem;
}
.ti-1 {
  text-indent: 1em;
}
.pre-txt {
  font-size: 0.24rem;
}
.fw-b {
  font-weight: bold;
}
</style>
